import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from  'react-router-dom';
import Home from './pages/Home';
import Tournaments from './pages/tournaments/Tournaments';
import Games from './pages/Games';
import News from './pages/News';
import SignUp from './pages/onboarding/SignUp';
import Login from './pages/onboarding/Login';
import TournamentDetails from './pages/tournaments/TournamentDetails'
import TournamentRegistration from './pages/tournaments/TournamentRegistration';
import NotFoundPage from './components/NotFoundPage';
import UserDashboard from './pages/UserDashboard';
import HostedTournaments from './pages/tournaments/HostedTournaments';
import JoinedTournaments from './pages/tournaments/JoinedTournaments';
import PaymentPage from './pages/PaymentPage'
import ProfilePage from './pages/ProfilePage'
import ThankYouPage from './pages/ThankYouPage'
import ForgetPassword from './pages/onboarding/ForgetPassword';

function App() {

  return (
    <> 
      <Router>
        <Routes> 
          <Route path='/' exact element={<Home/>} />
          <Route path='/tournaments' exact element={<Tournaments/>} />
          <Route path='/games' exact element={<Games/>} />
          <Route path='/news' exact element={<News/>} />
          <Route path='/sign-up' exact element={<SignUp/>} />
          <Route path='/login' exact element={<Login/>} />
          <Route path='/forget-password' exact element={<ForgetPassword/>} />
          <Route path='/user-dashboard' exact element={<UserDashboard/>}/>
          <Route path='/hosted-tournaments' exact element={<HostedTournaments/>} />
          <Route path='/joined-tournaments' exact element={<JoinedTournaments/>} />
          <Route path='/create-tournament' exact element={<TournamentRegistration/>} />
          <Route path='/payment' exact element={<PaymentPage/>} />
          <Route path="/tournaments/:tournamentURL" element={<TournamentDetails />} />
          <Route path='/profile' exact element={<ProfilePage/>} />
          <Route path='/thank-you' exact element={<ThankYouPage/>} />
          <Route path='*' element={<NotFoundPage/>}/>
        </Routes>

      </Router>
    </>
  );
}

export default App;
