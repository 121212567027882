import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HomeNavbar.css';
import Dropdown from './Dropdown';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const isLoggedIn = localStorage.getItem("isAuthenticated");

  const defaultProfilePicture = '../images/casual-life-3d-profile-picture-of-man-in-green-shirt-and-orange-hat.png'
  const [profilepic, setProfilePicture] = useState(defaultProfilePicture);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='home-navbar'>
        <div className='home-navbar-container'>

          <Link to='/' className='home-navbar-logo' onClick={closeMobileMenu}>
            <img src="./images/chedagaming_logo.png" alt="Cheda Gaming Logo" />
          </Link>

          <div className='home-menu-icon' onClick={handleClick}>
          {isLoggedIn && (
            <img 
            className="profilepic" 
            src={profilepic} 
            alt=''/>
          )}

          {
            isLoggedIn ? (<div></div>) :
          <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
          }
          </div>

          <ul className={click ? 'home-nav-menu active' : 'home-nav-menu'}>
            <li className='home-nav-item'>
              <Link to='/tournaments'
                className='home-nav-links' onClick={closeMobileMenu}>
                Tournaments
              </Link>
            </li>
            <li className='home-nav-item'>
              <Link
                to='/games'
                className='home-nav-links'
                onClick={closeMobileMenu}
              >
                Games
              </Link>
            </li>
            <li className='home-nav-item'>
              <Link
                to='/news'
                className='home-nav-links'
                onClick={closeMobileMenu}
              >
                News
              </Link>
            </li>
            
            <li
              className='home-nav-item'
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Link
                to={isLoggedIn ? '/create-tournament': '/login'}
                className='home-nav-links'
                onClick={closeMobileMenu}
              >
                Organize <i className='fas fa-caret-down' />
              </Link>
              {dropdown && <Dropdown />}
            </li>
         



            {/* Display the code below when the user is not logged in */}
            {
              isLoggedIn ? (<div></div>) : <ul className='mobile__signup__login'>
                <li>
                  <Link
                    to='/sign-up'
                    className='home-nav-links-mobile'
                    onClick={closeMobileMenu}
                  >
                    Sign Up
                  </Link>
                </li>
                <li>
                  <Link
                    to='/login'
                    className='home-nav-links-mobile'
                    onClick={closeMobileMenu}
                  >
                    Login
                  </Link>
                </li>

              </ul>
            }

              {/* Display the dashboard button when the user is logged in */}
              {isLoggedIn && (
                <li className='home-nav-item'>
                  <Link to='/user-dashboard' className='home-nav-links-mobile' onClick={closeMobileMenu}>
                    Dashboard
                  </Link>
                </li>
              )}

          </ul>

          {/* Display the dashboard button when the user is logged in */}
          {isLoggedIn && (
              <Link to='/user-dashboard' className="user_dashboard">
                 <img className="profilepic" src='../images/casual-life-3d-profile-picture-of-man-in-green-shirt-and-orange-hat.png' 
                 alt=''/>
              </Link>
          )}


          {/* Display the code below when the user is not logged in */}
          {
            isLoggedIn ? (<div></div>) :
              <div className='home-sign-up-login-btn'>
                <Link to='/sign-up'><button className="home-sign-up-btn">
                  Sign up now <i className="fas fa-arrow-right"></i> </button></Link>

                <Link to='/login'><button className="home-login-btn">
                  Login</button>
                </Link>
              </div>
          }
        </div>
      </nav>
    </>
  );
}

export default Navbar;