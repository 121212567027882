import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './DashboardNavbar.css';

function DashboardNavbar () {
  const defaultProfilePicture = '../images/casual-life-3d-profile-picture-of-man-in-green-shirt-and-orange-hat.png'
  const [profilepic, setProfilePicture] = useState(defaultProfilePicture);

  return (
    <>
      <nav className='dashboard-navbar'>
        <div className='dashboard-navbar-container'>
              <Link to='/profile'>
              <img 
              className="profilepic" 
              src={profilepic} 
              alt=''/>
              </Link>
        </div>
      </nav>
    </>
  );
}

export default DashboardNavbar;