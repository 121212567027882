import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import LoginNavbar from '../../components/LoginNavbar';
import Footer from '../../components/Footer';
import { getAuth, sendPasswordResetEmail, fetchSignInMethodsForEmail } from 'firebase/auth';


function ForgotPassword() {
  useEffect(() => {
    document.title = 'Forget Password'
  }, []);

  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();

  const handleResetPassword = async () => {
    setLoading(true);

    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        await sendPasswordResetEmail(auth, email);
        swal("Success", "A password reset link has been sent to your email.", "success");
        setEmail('');
      } else {
        swal("Error", "This email does not exist in our records.", "error");
      }
    } catch (error) {
      swal("Error", error.message, "error");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <LoginNavbar />
      <div className="login-body">
        <div className="login-main-section">
          <div className="login-box">
            <div className="login-title">
              <h1>Forgot Password</h1>
              <p>Enter your email address to reset your password.</p>
            </div>
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <div className="login-user-box">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>
            <hr className="login-form-line" />
            <button
              className="login-btn"
              onClick={handleResetPassword}
              disabled={loading}
            >
              {loading ? 'Sending email...' : 'Reset Password'}
            </button>
            <div className="rmb-frgt">
              <Link to="/login" className="login-link">
                Back to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ForgotPassword;
