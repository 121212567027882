import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-section'>
        <section className='footer-info'>
          <div className='web-desc'>
            <Link to="/"><img className="web-desc-img" src="../images/chedagaming_logo.png" alt="Cheda Gaming Logo"/></Link>
          <p>
          Earn while playing or hosting online tournaments
          </p>
          </div>
          <div className='footer-links'>
          <div>
            <ul className='footer-link-items-about'>
              <li>
            <h2>About Us</h2>
            </li>
            <li>
            <Link to='https://wa.link/uhba1k'>Help Center</Link>
            <Link to='/news'>News</Link>
            <Link to='/'>Our Story</Link>
            </li>
            </ul>
           
          </div>
          <div>
            <ul className='footer-link-items-contact'>
            <li>            
            <h2>Contact Us</h2>
            </li>
            <li>
            <p>Building an ecosystem of connecting gamers all over Africa.</p>
            <Link to='mailto:support@chedagaming.com'>support@chedagaming.com</Link>
            </li>
            </ul>
          </div>
      </div>
        </section>

        <section className='footer-socials-docs'>
          <hr/>
          <div className='footer-ending'>

            <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            <Link
              className='social-icon-link instagram'
              to='https://www.instagram.com/chedagaming'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
            <Link
              className='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </Link>
            <Link
              className='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i className='fab fa-twitter' />
            </Link>
            <Link
              className='social-icon-link twitter'
              to='https://www.linkedin.com/company/cheda-gaming'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </Link>
          </div>
          </div>
        </section>
      </div> 
        </div>
  );
}

export default Footer;
