import React from 'react'
import '../components/styles/ShowPotential.css'

function ShowPotential() {
  return (

          <div className="potential-container">
            <div className="potential-frame">
              <img className="potential-frame-img" src='./images/show-pontential.png' alt=''/>

              <div className="text-container">
                <div className="text-container-section-one">
                  <h2>SHOW YOUR POTENTIAL</h2>
                  <p>More and more people decide to join the eSport. 
                    And it is not only a booming sector but a world full of innovation and benefits. </p>
                  <p>And not only for individual players or the most professional teams. 
                    But also a way for friends to connect, for companies it is becoming an innovative method of building and training their work teams.</p>  
                </div>
                <div className="text-container-section-two">
                  <ul className="ul-text">
                    <li className="section-two-items-register">
                      <div className="register-title-container">
                        <img className="icon-img" src='./images/icons8-trophy.png' alt=''/>
                        <h3 className="register-title">Register Totally Free</h3>
                      </div>
                      <img className="arrow" src='./images/icons8-right-arrow.png' alt='red arrow'/>
                    </li>
                    <li className="section-two-items-find ">
                      <div className="find-title-container">
                        <img className="icon-img" src='./images/icons8-game.png' alt=''/>
                        <h3 className="find-title">Find your Game</h3> 
                      </div>
                      <img className="arrow" src='./images/icons8-right-arrow.png' alt='red arrow'/>
                    </li>
                    <li className="section-two-items-create">
                      <div className="create-title-container">
                        <img className="icon-img" src='./images/icons8-tournaments.png' alt=''/>
                        <h3 className="create-title">Create free/paid tournaments</h3>
                      </div>
                      <img className="arrow" src='./images/icons8-right-arrow.png' alt='red arrow'/>
                    </li>
                    <li className="section-two-items-rewards">
                      <div className="rewards-title-container">
                        <img className="icon-img" src='./images/icons8-reward.png' alt=''/>
                        <h3 className="rewards-title">Play to win different rewards</h3>
                      </div>
                      <img className="arrow" src='./images/icons8-right-arrow.png' alt='red arrow'/>
                    </li>
                  </ul>

                </div>

              </div>

            </div>
          </div>
  )
}

export default ShowPotential
