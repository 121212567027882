import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import DashboardNavbar from '../../components/DashboardNavbar'
import './styles/HostedTournaments.css'
import HostedTournamentsComponents from '../../components/HostedTournamentsComponents';

function HostedTournaments() {
  useEffect(() =>{
    document.title='Hosted Tournaments'
  }, []);

  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className=" dashboard-body">  
        <DashboardNavbar/>
        <Sidebar />
        {/* <HostedTournamentsComponents/>          */}
    </div>

  )
  
}
export default HostedTournaments;
