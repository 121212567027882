import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../pages/News.css';

function NotFoundPage () {
    return (
        <div className="news-hero-section">
        <div className="news-hero-title">
            <div className="tournaments-main-logo">
              <Link to='/'>
              <img className="news-logo" src="./images/tournaments-logo.png" alt="Cheda Gaming Logo"/>
              </Link>              
            </div>
            <div className="title-para">
              <h1>404 Error!</h1>
              <p>OOPS! You have entered the wrong page</p>
            </div>
            <div >
            <a href="/" className="host-tournaments-link" >
            <button className="host-tournaments-btn">Home</button>
            </a>
            </div>      
        </div>
        </div> 

    );
}

export default NotFoundPage;