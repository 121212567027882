import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentPage.css';
import HomeNavbar from '../components/HomeNavbar';
import Footer from '../components/Footer';

function PaymentPage() {
  useEffect(() =>{
    document.title='Payment'
  }, []);

  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const navigate = useNavigate();
  
  useEffect(() => {
      if (!isAuthenticated) {
        navigate('/login');
      }
  }, [isAuthenticated, navigate]);  
    
  return (
    <>
    <HomeNavbar />
    <div className="payment-hero-section">
        <div className="payment-hero-title">
            <div className="payment-title-para">
              <h1>Thank you for joining the tournament</h1>
              <p>Kindly make payment to the mpesa number below, and share the receipt via WhatsApp</p>
              <div className="mpesa">
              <h3>0724942865</h3>
              <h3>Safaricom</h3>
              <h3>Sidney Mulwa</h3>
              </div>
            </div>
            <div >
            <a href="https://wa.link/igknmi" className="host-tournaments-link" >
            <button className="host-tournaments-btn">Share Receipt</button>
            </a>
            </div>
            
            <div className="payment-qr-para">
            <hr className='signup-form-or-line' />
              <p>Scan QR Code to share the receipt</p>
                <div className='qr-bck'>
                <img className='qr' src='./images/cheda_gaming_qr.png'/>
                </div>  
            </div>      
        </div>
    </div>
    <Footer />    
    </>

  )
  
}
export default PaymentPage;
