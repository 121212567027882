import React from 'react';
import { createRoot } from 'react-dom/client';
// import ReactDOM from 'react-dom';
import App from './App';

// const analytics = getAnalytics(app);

// Before
// ReactDOM.render(<App />, document.getElementById('root'));

// After
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App />);

