import React from 'react';
import { Link } from 'react-router-dom';
import './LoginNavbar.css';

function LoginNavbar() {

  return (
    <>
     <nav className='login-navbar'>
      <div className='login-navbar-container'>
      <Link to='/' className='login-navbar-logo'>
          <img src="./images/chedagaming_logo.png" alt="Cheda Gaming Logo"/>
          </Link>
        <div className='sign-up-links'>
          <h3>Create an account
          </h3>
          <Link to='/sign-up'><button className="login-sign-up-btn">
              Sign up</button>
          </Link>
          </div>
          </div>
      </nav>
    </>
  );
}

export default LoginNavbar;