import React, {useEffect } from 'react';
import TournamentsNavbar from '../../components/TournamentsNavbar';
import TournamentsHeroSection from '../../components/TournamentsHeroSection';
import FeaturedGameSection from '../../components/FeaturedGameSection';
import LiveTournaments from '../../components/LiveTournaments';
import Footer from '../../components/Footer';
import ShowPotential from '../../components/ShowPotential'



function Tournaments() {
  useEffect(() => {
    document.title='Live Tournaments';
    }, []);



  return (
      <>
        <TournamentsNavbar/>
        <TournamentsHeroSection/>
        <FeaturedGameSection/>
        <LiveTournaments /> 
        <ShowPotential/>
        <Footer/>
      </>

  )
}

export default Tournaments

        