import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage} from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyC7A3NnyZdlwJqQe23UbvRajZ8UdYK473U",
  authDomain: "chedagaming-7f9c2.firebaseapp.com",
  projectId: "chedagaming-7f9c2",
  storageBucket: "chedagaming-7f9c2.firebasestorage.app",
  messagingSenderId: "574194172230",
  appId: "1:574194172230:web:548d00a1e06eb404a56547",
  measurementId: "G-G1TVHCT4RY"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const storage = getStorage(app);


