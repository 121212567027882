import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './News.css';

function News() {
  useEffect(() =>{
    document.title='News'
  }, []);
  return (
    <>
        <div className="news-hero-section">
        <div className="news-hero-title">
            <div className="tournaments-main-logo">
              <Link to='/'>
              <img className="news-logo" src="./images/tournaments-logo.png" alt="Cheda Gaming Logo"/>
              </Link>              
            </div>
            <div className="title-para">
              <h1>Coming Soon</h1>
              <p>Enjoy the competitive experience in the cheda gaming tournaments</p>
            </div>
            <div >
            <a href="/tournaments#join-tournaments-section" className="host-tournaments-link" >
            <button className="host-tournaments-btn">live tournaments</button>
            </a>
            </div>      
        </div>
        </div>   
    </>

  )
  
}
export default News
