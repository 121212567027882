import React, { useState } from 'react';
import { MenuItems } from './MenuItems';
import './Dropdown.css';
import { Link } from 'react-router-dom';

function Dropdown() {
  const [click, setClick] = useState(false);
  const isLoggedIn = localStorage.getItem("isAuthenticated");

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        {MenuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={isLoggedIn ? item.path : "/login"}
                onClick={() => setClick(false)}
              >
                <div className='play-online-tournaments'>
                  {item.img}
                  <div className='title-desc'>
                    <h2 className='title'>{item.title}  </h2>
                    <p className='desc'>{item.desc}</p>
                  </div>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default Dropdown;