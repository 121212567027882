import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import './HeroSection.css';

function HeroSection() {
  const isLoggedIn = localStorage.getItem("isAuthenticated");

  return (
    <div className="hero-container">

      <div className="main-section">
        <div className="main-section-container">
          <h1>Earn while hosting or playing online tournaments</h1>
          <p>Built specifically to connect gamers all across Africa.
            Reimagining how gamers hosts, stream, play and earn from online tournaments.</p>
          <div className='hero-btns'>
            <Link to={isLoggedIn ? "/create-tournament" : "/login"}>
              <button className='tournaments-btns'>
                <span className="circle" aria-hidden="true">
                  <span className="arrow_icon"></span>
                </span>
                <span className="button_text"><u>Create a tournament</u></span>
              </button>
            </Link>
          </div>
          <img className='fixtures' src='./images/fixtures.png' />
        </div>

      </div>


    </div>
  );
}

export default HeroSection;
