import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../config/firebaseConfig';
import LoginNavbar from '../../components/LoginNavbar';
import Footer from '../../components/Footer';
import './Login.css'

function Login() {
  useEffect(() => {
    document.title = 'Login'
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');

  const navigate = useNavigate();

  async function logIn() {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("user", JSON.stringify(user));
      navigate('/tournaments');
    } catch (e) {
      if (e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found') {
        setLoginError('Wrong email or password. Please try again.');
      } else {
        setLoginError(e.message);
      }
    } finally {
      setLoading(false);
    }
  }


  return (
    <>
      <LoginNavbar />
      <div className='login-body'>
        <div className='login-main-section'>
          <div className='login-box'>
            <div className='login-title'>
              <h1>Log in to Cheda Gaming</h1>
              <p>Welcome back! Please enter your details or
                log in with your social media account.</p>
            </div>

            <div >
              <div className="login-user-box">
              {loginError && <p className="span-text">{loginError}</p>}
                <input
                  id='email'
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required />
              </div>
              <div className="login-user-box">
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password} onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required />
              </div>

              <div className='rmb-frgt'>
                <Link to='/forget-password' className='forget-password'>
                  Forgot password
                </Link>
              </div>
              <hr className='login-form-line' />
              <button className='login-btn' onClick={logIn} disabled={loading} >
                {loading ? "Signing in..." : "LOG IN"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login
