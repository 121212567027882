import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import SignupNavbar from '../../components/SignupNavbar';
import './Signup.css';
import Footer from '../../components/Footer';
import { auth, database } from '../../config/firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set } from 'firebase/database';
import axios from 'axios';

const SLACK_WEBHOOK_URL = 'https://hooks.slack.com/services/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';


function Signup() {
  useEffect(() => {
    document.title = 'Signup';
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [termsOfService, setTermsOfService] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  async function createAccount() {
    setError("");
    setLoading(true);

    if (password !== passwordConfirmation) {
      setError('Password and confirm password do not match');
      setLoading(false);
      return;
    }

    if (!termsOfService) {
      setError('You must agree to the Terms of Service');
      setLoading(false);
      return;
    }

    try {
      // Create a new user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Save additional user data to Firebase Realtime Database
      const createdAt = new Date();
      await set(ref(database, `Users/${user.uid}`), {
        firstName,
        lastName,
        username,
        email,
        phone,
        createdAt,
        imageUrl: "",
        userId: user.uid
      });

      // Send Slack notification on successful signup
      await sendSlackNotification(username, email);

      // Store user authentication state
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("user", JSON.stringify({ uid: user.uid, username, email }));

      navigate('/login');
    } catch (e) {
      setError('An error occurred while creating your account. Please try again later.');
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  async function sendSlackNotification(username, email) {
    const payload = {
      text: `A new user signed up! 🎉\n*Username:* ${username}\n*Email:* ${email}`,
    };
  
    try {
      const response = await fetch(SLACK_WEBHOOK_URL, {
        method: 'POST',
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        console.error('Slack notification failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error sending Slack notification:', error);
    }
  }

  return (
    <>
      <SignupNavbar />
      <div className='signup-body'>
        <div className='signup-main-section'>
          <div className='signup-box'>
            <div className='signup-title'>
              <h1>Sign up to Cheda Gaming</h1>
              <p>Welcome to our community! Please enter your details.</p>
            </div>

            <div>
            <div>
            <div className="signup-user-box">
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoComplete="off"
                  required
                />
              </div>
              <div className="signup-user-box">
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  autoComplete="off"
                  required
                />
              </div>
              </div>
              <div className="signup-user-box">
                <input
                  type="text"
                  placeholder="Username/Gaming Tag"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  autoComplete="off"
                  required
                />
              </div>

              <div className="signup-user-box">
                <input
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                  required
                />
              </div>

              <div className="signup-user-box">
                <input
                  id="phone"
                  type="number"
                  placeholder="Phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  autoComplete="off"
                  required
                />
              </div>

              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className="signup-user-box">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <Link
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{background: "transparent", marginTop: '12px', marginLeft: '-35px'}}
                  >
                    {showPassword ? <AiFillEyeInvisible size={24} style={{color: '#FFF'}} /> : <AiFillEye size={24} style={{color: '#FFF'}}/>}
                  </Link>
              </div>

              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className="signup-user-box">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    required
                  />
                </div>
                <Link
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{background: "transparent", marginTop: '12px', marginLeft: '-35px'}}
                >
                  {showConfirmPassword ? <AiFillEyeInvisible size={24} style={{color: '#FFF'}} /> : <AiFillEye size={24} style={{color: '#FFF'}}/>}
                </Link>
              </div>  

              <hr className='pass-form-line' />

              <div className='terms-policy-container'>
                <input
                  id="terms-privacy-checkbox"
                  name="terms-privacy-checkbox"
                  type="checkbox"
                  checked={termsOfService}
                  onChange={(e) => setTermsOfService(e.target.checked)}
                />
                <label className='terms-privacy-checkbox' htmlFor="terms-privacy-checkbox">
                  I agree to the &nbsp;
                  <Link to='/' className='terms-policy'>Terms of Service</Link>
                  &nbsp; and &nbsp;
                  <Link to='/' className='terms-policy'>Privacy Policy</Link>.
                </label>
              </div>

              <hr className='signup-form-line' />
              <button className="signup-btn" onClick={createAccount} disabled={loading}>
                {loading ? "Creating your account..." : "CREATE AN ACCOUNT"}
              </button>
              {error && <p className="span-text">{error}</p>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
