import React, { useEffect, useState } from 'react';
import '../pages/tournaments/styles/TournamentDetails.css'
import { GrGallery, GrSubtract } from 'react-icons/gr';
import { ImLink } from 'react-icons/im';
import { Link, useParams, useNavigate} from 'react-router-dom';
import moment from 'moment';
import {auth, database } from '../config/firebaseConfig';
import { ref, update , get } from 'firebase/database';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import Swal from 'sweetalert2';




function TournamentDetailsSection() {

  const [tournament, setTournament] = useState({});
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const { tournamentURL } = useParams();
  const navigate = useNavigate();

  const [showMessage, setShowMessage] = useState(false);
  const isLoggedIn = localStorage.getItem("isAuthenticated");
  const currentUserId = auth?.currentUser?.uid;

    // Fetch current user data
  useEffect(() => {
      if (!currentUserId) {
        console.error("User not authenticated");
        navigate('/login'); // Redirect unauthenticated users to login
        return;
      }
  
      const fetchUserData = async () => {
        try {
          const userRef = ref(database, `Users/${currentUserId}`);
          const snapshot = await get(userRef);
  
          if (snapshot.exists()) {
            setUser(snapshot.val());
          } else {
            console.error("User data not found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
  
      fetchUserData();
  }, [currentUserId, navigate]);

  useEffect(() => {
    if (!tournamentURL) {
      console.error("Invalid tournament URL");
      navigate('/tournaments'); // Redirect if URL is missing
      return;
    }

    const fetchTournamentData = async () => {
      try {
        const tournamentsRef = ref(database, 'HostedTournaments');
        const snapshot = await get(tournamentsRef);

        if (snapshot.exists()) {
          const tournamentsData = snapshot.val();
          // Find tournament by matching tournamentURL
          const matchedTournamentKey = Object.keys(tournamentsData).find(
            key => tournamentsData[key].tournamentURL === tournamentURL
          );

          if (matchedTournamentKey) {
            setTournament(tournamentsData[matchedTournamentKey]);
          } else {
            console.error("Tournament not found");
            navigate('/tournaments'); 
          }
        } else {
          console.error("No tournaments found.");
          navigate('/tournaments'); 
        }
      } catch (error) {
        console.error("Error fetching tournament:", error);
        navigate('/tournaments');
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentData();
  }, [tournamentURL, navigate]);

  const handleFlutterPayment = useFlutterwave({
    public_key: 'FLWPUBK_TEST-8496e96ae6bd103e18415cb799840f51-X',
    tx_ref: Date.now(),
    amount: parseFloat(tournament?.fee || 0), 
    currency: 'KES', 
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: user?.email || '',
      phone_number: user?.phoneNumber || '',
      name: `${user?.firstName} ${user.lastName}` || '',
    },
    // customizations: {
    //   title: 'Tournament Registration',
    //   description: `Registration for ${tournament?.tournamentName}`,
    //   logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    // },
  });

  const handlePaymentSuccess = async (response) => {
    console.log('Payment successful:', response);

    try {
      const tournamentRef = ref(database, `HostedTournaments/${tournament.tournamentId}`);
      const snapshot = await get(tournamentRef);

      if (snapshot.exists()) {
        const tournamentData = snapshot.val();
        const updatedParticipants = Array.isArray(tournamentData.participants)
          ? [...tournamentData.participants, currentUserId]
          : [currentUserId];

        await update(tournamentRef, { participants: updatedParticipants });
        console.log("Tournament updated successfully with new participant.");
      } else {
        console.error("Tournament data not found.");
      }
    } catch (error) {
      console.error("Error updating tournament participants:", error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }


  const handlePermalinkClick = () => {
    const permalink = window.location.href;
    navigator.clipboard.writeText(permalink)
      .then(() => console.log('Permalink copied to clipboard:', permalink))
      .catch((error) => console.error('Failed to copy permalink:', error));

    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  };

  // const handleImageOnclick = () => {
  //   navigate ('/games')
  // };
    

  return (
      <div className="tournament-info">
        <div className="main_tournament_details_container">
        <div className="first__section">

        <div className="container-wrap">
          <div className="item-container">
            <div className="more-like-this-item">
              <GrGallery className="icon_tournament" />
              <Link to='/tournaments' className="more-item-link">
                <span className="span-text">More like this</span>
              </Link>
            </div>
            <div><GrSubtract className="dash icon_tournament" /></div>
            <div className="permalink-item" onClick={handlePermalinkClick}>
              <ImLink className="icon_tournament" />
              <span className="span-text">Share tournament</span>
            </div><br/>
            {showMessage && <p className="message">Tournament link has been copied successfully</p>}
          </div>

          <div className="title-wrapper">            
            <h1>{tournament?.tournamentName}</h1>
            <div className="first-item-container">
              <h4 className="first-item-h">{moment(tournament.startDateTime).format("MMMM D, YYYY h:mm A")}</h4>
              <div><GrSubtract className="dash icon" /></div>
              <div className="location-container-wrapper">
                <h4 className="first-item-h">Location:</h4> 
                <p>{tournament.location}</p>
              </div>
              <div><GrSubtract className="dash icon" /></div>
              <div className="location-container-wrapper">
                <h4 className="first-item-h">Reg Fee:</h4>
                <p>KSH {tournament.fee}</p>    
              </div>
            </div> 
            <p>{tournament.description}</p>
            <div className="location-container-wrapper" style={{marginTop: 20}}>
              <h4 className="first-item-h">Participants:</h4>
              <p>{tournament.participants?.length || 0}</p>
            </div>
            <div className="prize-wrapper">
              <h2>Prize money:</h2>
              <h2>KSH {tournament.prize}</h2>
            </div>

            <button 
              onClick={() => {
                // Check if the user is already a participant
                if (tournament.participants?.includes(currentUserId)) {
                  Swal.fire({
                    icon: 'info',
                    title: 'Already Registered',
                    text: 'You have already joined this tournament!',
                    confirmButtonText: 'OK',
                  });
                  return; 
                }
                handleFlutterPayment({
                  callback: (response) => {
                    if (response.status === 'successful') {
                      handlePaymentSuccess(response);
                    } else {
                      console.error("Payment failed or cancelled:", response);
                    }
                    closePaymentModal(); 
                  },
                  onClose: () => {
                    console.log("Payment modal closed.");
                  },
                });
              }}
              className="join-tournaments-btn">
              Join Tournament
            </button>
          </div> 

          <div className="tournament-img-container mobile-img">
            <img className="tournament-image" src={tournament.tournamentImage}/>
          </div>

        <div className="games__list mobile-img">
          <h4 className="featured-text span-text">Featured Games</h4>   
          <ul>
            <li>
                <iframe src="https://www.youtube.com/embed/tv7LfFeamsc" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/GITzbGIiNKg" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/XhP3Xh4LMA8" frameborder="0" allowFullScreen></iframe>
            </li>
        </ul>
        </div>

          
        </div>  

        <div className="games__list desktop-img">
          <h4 className="featured-text span-text">Featured Games</h4>   
          <ul>
              <li>
                  <iframe src="https://www.youtube.com/embed/tv7LfFeamsc" frameborder="0" allowFullScreen></iframe>
              </li>
              <li>
                  <iframe src="https://www.youtube.com/embed/GITzbGIiNKg" frameborder="0" allowFullScreen></iframe>
              </li>
              <li>
                  <iframe src="https://www.youtube.com/embed/XhP3Xh4LMA8" frameborder="0" allowFullScreen></iframe>
              </li>
          </ul>
          <div className="final-btn">
            <div>
              <Link to="/tournaments">
              <button className="join-tournaments-btn">
                Live Tournaments
              </button>
              </Link>
            </div>

              <Link to="/create-tournament">
              <button  className="live-btn join-tournaments-btn">
                Create Tournament
              </button>
              </Link>

          </div>
        </div>  

        {/* <div className="games__list desktop-img">
        <h4 className="featured-text span-text">Featured Games</h4>   
        <ul>
            <li>
                <iframe src="https://www.youtube.com/embed/tv7LfFeamsc" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/GITzbGIiNKg" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/XhP3Xh4LMA8" frameborder="0" allowFullScreen></iframe>
            </li>
        </ul>
        </div> */}

        </div>
        
        <div className="tournament-img-container desktop-img">
          <img className="tournament-image" src={tournament.tournamentImage}/>
        </div>

        </div>
      </div>

  );
}

export default TournamentDetailsSection;