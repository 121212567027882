import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineLogout } from 'react-icons/md';
import { HiOutlineHome, HiOutlineUsers } from 'react-icons/hi';
import { GrGamepad } from 'react-icons/gr';
import './Sidebar.css';


const Sidebar = () => {
  const location = useLocation();
  const activeIcon = location.pathname.slice(1);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("user");
    navigate('/login');
  };

  return (
    <div className="sidebar">
      <div className="logo">
        <Link to="/" className="dash-navbar-logo">
          <img src="./images/sidebar-nav-logo.png" alt="Cheda Gaming Logo" />
        </Link>
      </div>

      <nav className="sidebar-nav">
        <ul>
        <li className={activeIcon === 'dashboard' ? 'active' : ''}>
            <Link to="/user-dashboard">
              <HiOutlineHome className="icon" />
            </Link>
          </li>

          <li className={activeIcon === 'join' ? 'active' : ''}>
            <Link to="#">
              <HiOutlineUsers className="icon" />
            </Link>
          </li>
          <li className={activeIcon === 'host' ? 'active' : ''}>
            <Link to="#">
              <GrGamepad className="icon" />
            </Link>
          </li>
          <li className={activeIcon === 'logout' ? 'active' : ''}>            
              <MdOutlineLogout className="icon" onClick={handleLogout}/>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
